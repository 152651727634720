import {computed, inject, Injectable, NgZone, OnDestroy, signal, Signal} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridService implements OnDestroy {
  private ngZone = inject(NgZone);


  resizeSub: Subscription;

  _screenWidth = signal<number>(typeof window === 'undefined' ? 0 : window.innerWidth);

  isExtraSmallSignal = computed(() => this.screenWidthSignal() < this.extraSmallSize);
  isSmallSignal = computed(() => this.screenWidthSignal() >= this.extraSmallSize);
  isMediumSignal = computed(() => this.screenWidthSignal() >= this.smallSize);
  isLargeSignal = computed(() => this.screenWidthSignal() >= this.mediumSize);
  isExtraLargeSignal = computed(() => this.screenWidthSignal() >= this.largeSize);

  _screenHeight = signal<number>(typeof window === 'undefined' ? 0 : window.innerHeight);

  get screenWidth(): number {
    if (typeof window === 'undefined') {
      throw new Error('Screen width cannot be used in the server side');
    }
    return this._screenWidth() ?? window.innerWidth;
  }

  set screenWidth(value: number) {
    this._screenWidth.set(value);
  }

  get screenWidthSignal() {
    return this._screenWidth.asReadonly();
  }

  set screenHeight(value: number) {
    this._screenHeight.set(value);
  }

  get screenHeight(): Signal<number> {
    return this._screenHeight.asReadonly();
  }

  init() {
    this.ngZone.runOutsideAngular(() => {
      this.resizeSub = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(event => {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
      });
    });
  }

  ngOnDestroy() {
    this.resizeSub?.unsubscribe();
  }

  get extraSmallSize() {
    return 567;
  }

  get smallSize() {
    return 768;
  }

  get mediumSize() {
    return 992;
  }

  get largeSize() {
    return 1200;
  }

  /**
   * @deprecated - Use isExtraSmallSignal instead
   */
  get isExtraSmall() {
    return this.screenWidth < this.extraSmallSize;
  }

  /**
   * @deprecated - Use isSmallSignal instead
   */
  get isSmall() {
    return this.screenWidth >= this.extraSmallSize;
  }

  /**
   * @deprecated - Use isMediumSignal instead
   */
  get isMedium() {
    return this.screenWidth >= this.smallSize;
  }

  /**
   * @deprecated - Use isLargeSignal instead
   */
  get isLarge() {
    return this.screenWidth >= this.mediumSize;
  }

  /**
   * @deprecated - Use isExtraLargeSignal instead
   */
  get isExtraLarge() {
    return this.screenWidth >= 1200;
  }

}
